@import "colors";

.nameDescriptionPopper {

    .popper {
        z-index: 999999999;
        width: 600px;
        padding: 22px; } }

.row {
    flex-direction: row;
    align-items: center;
    margin-bottom: 25px; }

.icon {
    width: 40px;
    height: 40px;
    border-radius: 28px;
    background-color: $sideNav-dk-orange;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    margin-right: 16px; }

.icon  img {
    width: 100%;
    height: 100%; }

.headingText {
    margin-bottom: 15px;
    display: flex;
    align-items: center; }

.headingText p {
    margin: 0;
    font-size: 22px;
    font-family: "Mulish", sans-serif;
    line-height: 32px;
    font-weight: 100;
    color: $sharePopper-dk-grey; }

.nameBar input {
    width: 100%;
    border: 1px solid #707070;
    border-radius: 10px;
    padding: 12px;
    background-color: #F5F8FF;
    margin-bottom: 12px;
    font-family: "Mulish", sans-serif;
    font-size: 16px;
    line-height: 23px;
    color: #606060;
    font-weight: 100; }


.nameBar input::placeholder {
    font-family: "Mulish", sans-serif;
    letter-spacing: 0.18px;
    color: #878991;
    font-size: 16px;
    line-height: 23px;
    font-weight: 100; }

.nameBar input:focus-visible {
    border: 1px solid #F66322;
    outline: #F66322; }

.nameLabelText {
    margin: 0;
    font-size: 18px;
    font-family: "Mulish", sans-serif;
    line-height: 32px;
    font-weight: 400;
    color: $sharePopper-dk-grey;
    letter-spacing: 0.18px;
    margin-bottom: 10px; }

.descriptionBar textarea {
    width: 100%;
    height: 300px;
    border: 1px solid #707070;
    border-radius: 10px;
    padding: 12px;
    background-color: #F5F8FF;
    margin-bottom: 12px;
    resize: none;
    font-weight: 100;
    font-family: "Mulish", sans-serif;
    font-size: 16px;
    line-height: 23px;
    color: #606060; }




.descriptionBar textarea::placeholder {
    font-family: "Mulish", sans-serif;
    letter-spacing: 0.18px;
    color: #878991;
    font-size: 16px;
    line-height: 23px;
    font-weight: 100; }

.descriptionBar textarea:focus-visible {
    border: 1px solid #F66322;
    outline: #F66322; }

.descriptionLabelText {
    margin-top: 20px;
    margin-bottom: 0;
    font-size: 18px;
    font-family: "Mulish", sans-serif;
    line-height: 32px;
    font-weight: 400;
    color: $sharePopper-dk-grey;
    letter-spacing: 0.18px;
    margin-bottom: 10px; }

.submitDoneBtn {
    border: 1px solid #F66322;
    border-radius: 9px;
    padding: 10px 38px;
    margin-right: -15px; }

.closeBtn {
    border: 1px solid #F66322;
    border-radius: 9px;
    padding: 10px 38px;
    margin-right: 20px;
    background: $white;
    color: #F66322; }
